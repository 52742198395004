import { useContext } from "react";

import { AuthContext } from "../context/AuthContext";
import { getBrowserAgent, getIPAddress } from "../utils/Common";

import { useChips } from "./useChips";
import { useFourSeasonBetPanel } from "../page-game/four-season/hook/useFourSeasonBetPanel";
import { message } from "antd";

export const BetHistoryVersion = 4;
export function useBetPanel() {
    const { balance, setBalance } = useContext(AuthContext);

    const { handleGetSelectedChip, handlePlaceChipFixed } = useChips();
    const { handlePlaceBetFourSeason, handlePlaceChipFourSeasonFixed } = useFourSeasonBetPanel();

    //IndexedDB extension
    const DeleteRow = async (store, key) => {
        return new Promise((resolve, reject) => {
            const delete_request = store?.delete(key);

            delete_request.onerror = (e) => {
                return reject(e.target.error);
            };
            delete_request.onsuccess = (e) => {
                return resolve();
            };
        });
    };
    const GenerateRandomText = (length) => {
        let result = "";
        for (var i = 0; i < length; i++) {
            result += Math.floor(Math.random() * 10).toString();
        }
        return result;
    };
    const CreateStore = (db) => {
        // const store = db.createObjectStore("Log_Chips_Placed", { keyPath: "UniqueID" });
        const store = db.createObjectStore(`Log_Chips_Placed-${BetHistoryVersion}`, { keyPath: "Key", autoIncrement: true });

        store.createIndex("UniqueID", ["UniqueID"], { unique: true });
        store.createIndex("TableID", ["TableID"], { unique: false });
        store.createIndex("RoundUniqueID", ["RoundUniqueID"], { unique: false });
        store.createIndex("ChipImage", ["ChipImage"], { unique: false });
        store.createIndex("ChipAmount", ["ChipAmount"], { unique: false });
        store.createIndex("BetCode", ["BetCode"], { unique: false });

        // 1 = current, 2 = pending, 3 = success
        store.createIndex("Status", ["Status"], { unique: false });
    };
    //End Extension

    /**
     *
     * @param {array of string} bet_items data2 return from table-detail
     * @param {string} bet_code data-id
     * @returns
     */
    function handleDisplayRate(bet_items, bet_code) {
        const result = bet_items?.filter((item) => item?.betCode === bet_code);
        return result[0]?.betRate;
    }

    /**
     * @param {string} key bet items's data-id
     * @param {object} selected_chip {image: "", value: 10}
     * @param {object} table_detail
     * @param {object} dealer_signal
     * @returns
     */
    function handleValidateBet(key, selected_chip, table_detail, dealer_signal) {
        return new Promise((resolve, reject) => {
            const request = window.indexedDB.open("Galaxy_Bet_History", BetHistoryVersion);
            request.onupgradeneeded = (e) => {
                CreateStore(e.target.result);
            };
            request.onsuccess = (e) => {
                const db = e.target.result;
                const transaction = db.transaction(`Log_Chips_Placed-${BetHistoryVersion}`, "readwrite");
                const store = transaction.objectStore(`Log_Chips_Placed-${BetHistoryVersion}`);

                const round_unique_id_index = store.index("RoundUniqueID");
                const round_unique_id_query = round_unique_id_index.getAll(getBrowserAgent() === "Safari" ? dealer_signal?.gameSummarySrno : [dealer_signal?.gameSummarySrno]);
                // round_unique_id_query.onsuccess = async (e) => {
                //     let total_bet = 0;
                //     let cur_betCode = 0;
                //     for (const data of e.target.result) {
                //         total_bet += data.ChipAmount;
                //         if (data.BetCode === key && data.RoundUniqueID === dealer_signal?.gameSummarySrno) {
                //             cur_betCode += data.ChipAmount;
                //         }
                //     }

                //     if (total_bet < table_detail?.maxBet && balance >= selected_chip?.value) {
                //         if (total_bet + selected_chip?.value <= table_detail?.maxBet && balance >= selected_chip?.value) {
                //             setBalance(balance - selected_chip?.value);
                //             return resolve({
                //                 curBetCode: cur_betCode + selected_chip?.value,
                //                 totalBet: total_bet + selected_chip?.value,
                //                 curValue: selected_chip?.value,
                //             });
                //         }

                //         if (total_bet + selected_chip?.value > table_detail?.maxBet) {
                //             let amount = table_detail?.maxBet - total_bet;
                //             setBalance(balance - amount);
                //             return resolve({
                //                 curBetCode: cur_betCode + amount,
                //                 totalBet: table_detail?.maxBet,
                //                 curValue: amount,
                //             });
                //         }

                //         if (balance < selected_chip?.value) {
                //             let temp = balance;
                //             setBalance(0);
                //             return resolve({
                //                 curBetCode: cur_betCode + temp,
                //                 totalBet: total_bet + temp,
                //                 curValue: temp,
                //             });
                //         }
                //     }

                //     if (balance < selected_chip?.value) {
                //         return reject("Insufficient Balance");
                //     }

                //     return reject("Bet limit exceed");
                // };
                // transaction.oncomplete = (e) => {
                //     db.close();
                // };
                round_unique_id_query.onsuccess = async (e) => {
                    let total_bet = 0;
                    let cur_betCode = 0;

                    for (const data of e.target.result) {
                        total_bet += data.ChipAmount;
                        if (data.BetCode === key && data.RoundUniqueID === dealer_signal?.gameSummarySrno) {
                            cur_betCode += data.ChipAmount;
                        }
                    }

                    const limit = { baccarat: { T: 0.1, PP: 0.1, BP: 0.1, B6: 0.1, B6T: 0.1, SMT: 0.1, BT: 0.1, SPT: 0.1, TT: 0.1 }, "four seasons": { BS1J: 0.01, PS1J: 0.01, PS2J: 0.01, PS3J: 0.01 } };
                    const noOverLimit = limit?.hasOwnProperty(table_detail?.gameType.toLowerCase())?.hasOwnProperty(key) ? cur_betCode + selected_chip?.value <= table_detail?.maxBet * limit[table_detail?.gameType.toLowerCase()][key] : true;

                    const curBetAmount = noOverLimit ? selected_chip?.value : table_detail?.maxBet * limit[table_detail?.gameType.toLowerCase()][key] - cur_betCode;
                    const curTotalBet = total_bet + curBetAmount;

                    const enoughBal = balance >= curBetAmount;

                    const finalBetAmount = !enoughBal ? (balance < table_detail?.maxBet ? Math.trunc(balance) : table_detail?.maxBet - total_bet) : curTotalBet <= table_detail?.maxBet ? curBetAmount : table_detail?.maxBet - total_bet;
                    if (total_bet < table_detail?.maxBet) {
                        setBalance((prev) => prev - finalBetAmount);
                        return resolve({
                            curBetCode: cur_betCode + finalBetAmount,
                            totalBet: total_bet + finalBetAmount,
                            curValue: finalBetAmount,
                        });
                    } else {
                        return reject("Bet limit exceed");
                    }
                };
                transaction.oncomplete = (e) => {
                    db.close();
                };
            };
        });
    }
    /**
     *
     * @param {string} key bet item's data-id
     * @param {object} table_detail
     * @param {object} dealer_signal
     * @returns
     */
    function handlePlaceBet(key, table_detail, dealer_signal, tempChip = undefined) {
        return new Promise(async (resolve, reject) => {
            try {
                //request_1 for get selected chip
                let selected_chip =
                    tempChip ??
                    (await handleGetSelectedChip().catch((error) => {
                        return reject(error);
                    }));

                let validate_bet = await handleValidateBet(key, selected_chip, table_detail, dealer_signal).catch((error) => {
                    return reject(error);
                });

                const result = await Promise.all([selected_chip, validate_bet]);
                if (result[0] && result[1]) {
                    //open database || add bet item
                    const request = window.indexedDB.open("Galaxy_Bet_History", BetHistoryVersion);
                    //create table if does not exist
                    request.onupgradeneeded = (e) => {
                        CreateStore(e.target.result);
                    };

                    //after create table or table already exist will direct come here
                    request.onsuccess = (e) => {
                        const db = e.target.result;
                        const transaction = db.transaction(`Log_Chips_Placed-${BetHistoryVersion}`, "readwrite");
                        const store = transaction.objectStore(`Log_Chips_Placed-${BetHistoryVersion}`);

                        //delete previous record
                        const table_id_index = store.index("TableID");
                        // const table_id_query = table_id_index.getAll(`${table_detail?.tableCode}-${table_detail?.type}`);
                        // const table_id_query = table_id_index.getAll([`${table_detail?.tableCode}-${table_detail?.type}`]);
                        const table_id_query = table_id_index.getAll(getBrowserAgent() === "Safari" ? `${table_detail?.tableCode}-${table_detail?.type}` : [`${table_detail?.tableCode}-${table_detail?.type}`]);

                        table_id_query.onsuccess = async (e) => {
                            for (const data of e.target.result) {
                                if (data.RoundUniqueID !== dealer_signal?.gameSummarySrno) {
                                    await DeleteRow(store, data.Key);
                                }
                            }
                        };

                        //add new record
                        const unique_id = `${dealer_signal?.gameSummarySrno ?? GenerateRandomText(8)}${GenerateRandomText(4)}`;
                        store.add({
                            UniqueID: unique_id,
                            RoundUniqueID: dealer_signal?.gameSummarySrno,
                            TableID: `${table_detail?.tableCode}-${table_detail?.type}`,
                            ChipImage: selected_chip?.image,
                            ChipAmount: validate_bet?.curValue,
                            BetCode: key,
                            Status: 1,
                        });

                        transaction.oncomplete = () => {
                            db.close();
                        };
                        return resolve({
                            ...selected_chip,
                            uniqueID: unique_id,
                            roundUniqueId: dealer_signal?.gameSummarySrno,
                            totalBet: validate_bet?.totalBet,
                            curBetCode: validate_bet?.curBetCode,
                            curValue: validate_bet?.curValue,
                        });
                    };
                }
            } catch (error) {
                console.log(error);
                return reject(error);
            }
        });
    }

    /**
     *
     * @param {object} dealer_signal
     * @returns
     */
    function handleUndoBet(dealer_signal) {
        return new Promise(async (resolve, reject) => {
            //open database || add bet item
            const request = window.indexedDB.open("Galaxy_Bet_History", BetHistoryVersion);
            //create table if does not exist
            request.onupgradeneeded = (e) => {
                CreateStore(e.target.result);
                return reject("No bet record found");
            };
            //after create table or table already exist will direct come here
            request.onsuccess = (e) => {
                const db = e.target.result;
                const transaction = db.transaction(`Log_Chips_Placed-${BetHistoryVersion}`, "readwrite");
                const store = transaction.objectStore(`Log_Chips_Placed-${BetHistoryVersion}`);

                //delete previous record
                const round_unique_id_index = store.index("RoundUniqueID");
                const round_unique_id_query = round_unique_id_index.getAll(getBrowserAgent() === "Safari" ? dealer_signal?.gameSummarySrno : [dealer_signal?.gameSummarySrno]);
                round_unique_id_query.onsuccess = async (e) => {
                    let last_data = e.target.result.pop();
                    if (last_data && last_data?.Status === 1) {
                        await DeleteRow(store, last_data.Key).then(() => {
                            const element = document.querySelectorAll(`[data-id-unique="${last_data.UniqueID}"]`);
                            element.forEach((item) => item?.remove());
                            return resolve({
                                betCode: last_data.BetCode,
                                amount: last_data.ChipAmount,
                            });
                        });
                    }
                    return reject("Unable to undo bet");
                };

                transaction.oncomplete = () => {
                    db.close();
                };
            };
        });
    }
    /**
     *
     * @param {object} table_detail
     * @param {object} dealer_signal
     * @param {array of object} chips_set
     * @returns
     */
    function handleReBet(table_detail, dealer_signal, chips_set, roadMaps) {
        return new Promise(async (resolve, reject) => {
            const request = window.indexedDB.open("Galaxy_Bet_History", BetHistoryVersion);
            //create table if does not exist
            request.onupgradeneeded = (e) => {
                CreateStore(e.target.result);
                return reject("No bet record found");
            };
            //after create table or table already exist will direct come here
            request.onsuccess = (e) => {
                const db = e.target.result;
                const transaction = db.transaction(`Log_Chips_Placed-${BetHistoryVersion}`, "readwrite");
                const store = transaction.objectStore(`Log_Chips_Placed-${BetHistoryVersion}`);

                const table_id_index = store.index("TableID");
                const table_id_query = table_id_index.getAll(getBrowserAgent() === "Safari" ? `${table_detail?.tableCode}-${table_detail?.type}` : [`${table_detail?.tableCode}-${table_detail?.type}`]);
                table_id_query.onsuccess = async (e) => {
                    for (const data of e.target.result) {
                        if (data.RoundUniqueID !== dealer_signal?.gameSummarySrno) {
                            await DeleteRow(store, data.Key);
                        }
                    }

                    let bet_items = [];
                    let total_bet = 0;
                    for (const data of e.target.result) {
                        if (table_detail?.gameType?.toLowerCase() !== "baccarat" || (table_detail?.gameType?.toLowerCase() === "baccarat" && roadMaps?.data2?.total < 50 && (data.BetCode !== "BP" || data.BetCode !== "PP"))) {
                            await handlePlaceBet(data.BetCode, table_detail, dealer_signal, {
                                image: data?.ChipImage,
                                value: data?.ChipAmount,
                            }).then(async (result) => {
                                handlePlaceChipFourSeasonFixed(chips_set, data.BetCode, { image: result?.image, value: result?.curBetCode }, result?.uniqueID, result?.roundUniqueId);
                                total_bet += data.ChipAmount;
                                const index = bet_items?.findIndex((item) => item.betCode === data?.BetCode);
                                if (index <= -1) {
                                    bet_items.push({
                                        betCode: data?.BetCode,
                                        amount: data?.ChipAmount,
                                    });
                                } else {
                                    bet_items[index].amount += data?.ChipAmount;
                                }
                            });
                        }
                    }

                    return resolve({ totalBet: total_bet, betItems: bet_items });
                };
                transaction.oncomplete = (e) => {
                    db.close();
                };
            };
        });
    }
    /**
     *
     * @param {object} table_detail
     * @param {object} dealer_signal
     * @param {array of object} chips_set
     * @returns
     */
    function handleDoubleBet(table_detail, dealer_signal, chips_set) {
        return new Promise((resolve, reject) => {
            const request = window.indexedDB.open("Galaxy_Bet_History", BetHistoryVersion);
            //create table if does not exist
            request.onupgradeneeded = (e) => {
                CreateStore(e.target.result);
                return reject("No bet record found");
            };
            //after create table or table already exist will direct come here
            request.onsuccess = (e) => {
                const db = e.target.result;
                const transaction = db.transaction(`Log_Chips_Placed-${BetHistoryVersion}`, "readwrite");
                const store = transaction.objectStore(`Log_Chips_Placed-${BetHistoryVersion}`);
                const table_id_index = store.index("TableID");
                const table_id_query = table_id_index.getAll(getBrowserAgent() === "Safari" ? `${table_detail?.tableCode}-${table_detail?.type}` : [`${table_detail?.tableCode}-${table_detail?.type}`]);
                table_id_query.onsuccess = async (e) => {
                    const total_bet = e.target.result?.reduce((accumulator, curr) => accumulator + curr?.ChipAmount, 0);

                    if (total_bet * 2 <= table_detail?.maxBet && balance >= total_bet * 2) {
                        let bet_items = [];
                        for (const data of e.target.result) {
                            await handlePlaceBet(data.BetCode, table_detail, dealer_signal).then((result) => {
                                handlePlaceChipFourSeasonFixed(chips_set, data.BetCode, { image: result?.image, value: result?.curBetCode }, result?.uniqueID, result?.roundUniqueId);

                                const index = bet_items?.findIndex((item) => item.betCode === data?.BetCode);
                                if (index <= -1) {
                                    bet_items.push({
                                        betCode: data?.BetCode,
                                        amount: data?.ChipAmount,
                                    });
                                } else {
                                    bet_items[index].amount += data?.ChipAmount;
                                }
                            });
                        }
                        return resolve({ totalBet: total_bet * 2, betItems: bet_items });
                    }
                    return reject("Bet limit exceed");
                };

                transaction.oncomplete = (e) => {
                    db.close();
                };
            };
        });
    }

    /**
     *
     * @param {object} dealer_signal
     * @returns
     */
    function handleSortBetItems(dealer_signal, table_info) {
        return new Promise((resolve, reject) => {
            let temp_obj = {};

            const request = window.indexedDB.open("Galaxy_Bet_History", BetHistoryVersion);
            request.onupgradeneeded = (e) => {
                CreateStore(e.target.result);
                return resolve({});
            };
            request.onsuccess = (e) => {
                const db = e.target.result;
                const transaction = db.transaction(`Log_Chips_Placed-${BetHistoryVersion}`, "readwrite");
                const store = transaction.objectStore(`Log_Chips_Placed-${BetHistoryVersion}`);

                const round_unique_id_index = store.index("RoundUniqueID");
                const round_unique_id_query = round_unique_id_index.getAll(getBrowserAgent() === "Safari" ? dealer_signal?.gameSummarySrno : [dealer_signal?.gameSummarySrno]);
                round_unique_id_query.onsuccess = async (e) => {
                    if (e.target.result.length > 0) {
                        let currentBet = e.target.result?.filter((x) => x.Status === 1);
                        if (table_info?.split("_")[0] === "four-seasons") {
                            let arrayBetCode = [];

                            currentBet.forEach((element) => {
                                if (element.BetCode.includes("-")) {
                                    const betCodeSplit = element.BetCode.split("-");
                                    betCodeSplit.forEach((elements) => {
                                        arrayBetCode.push({
                                            BetCode: elements,
                                            ChipAmount: element.ChipAmount / 2,
                                        });
                                    });
                                } else {
                                    arrayBetCode.push({
                                        BetCode: element.BetCode,
                                        ChipAmount: element.ChipAmount,
                                    });
                                }
                            });

                            var holder = {};

                            arrayBetCode.forEach(function (d) {
                                if (holder.hasOwnProperty(d.BetCode)) {
                                    holder[d.BetCode] = holder[d.BetCode] + d.ChipAmount;
                                } else {
                                    holder[d.BetCode] = d.ChipAmount;
                                }
                            });

                            currentBet?.forEach((element) => {
                                store.put({ ...element, Status: 2 });
                            });

                            var obj2 = [];

                            for (var prop in holder) {
                                obj2.push({ BetCode: prop, ChipAmount: holder[prop] });
                            }

                            const ethnicitiesObject = obj2.reduce((previousObject, currentObject) => {
                                return Object.assign(previousObject, {
                                    [currentObject.BetCode]: currentObject.ChipAmount,
                                });
                            }, {});
                            return resolve(ethnicitiesObject);
                        } else {
                            temp_obj = currentBet?.reduce((a, v) => {
                                if (a[v.BetCode] > 0) {
                                    return { ...a, [v.BetCode]: a[v.BetCode] + v.ChipAmount };
                                } else {
                                    return { ...a, [v.BetCode]: v.ChipAmount };
                                }
                            }, {});

                            currentBet?.forEach((element) => {
                                store.put({ ...element, Status: 2 });
                            });

                            return resolve(temp_obj);
                        }
                    }
                };

                transaction.oncomplete = (e) => {
                    db.close();
                };
            };
        });
    }

    /**
     *
     * @param {string} round_unique_id gameSummarySrno
     * @param {boolean} clear_indexeddb
     */
    function handleRemoveAllChip(round_unique_id, clear_indexeddb = false, status = 0, setTotalBet, connection, tableInfo) {
        return new Promise((resolve, reject) => {
            if (clear_indexeddb) {
                const request = window.indexedDB.open("Galaxy_Bet_History", BetHistoryVersion);
                request.onupgradeneeded = (e) => {
                    CreateStore(e.target.result);
                };
                request.onsuccess = (e) => {
                    const db = e.target.result;
                    const transaction = db.transaction(`Log_Chips_Placed-${BetHistoryVersion}`, "readwrite");
                    const store = transaction.objectStore(`Log_Chips_Placed-${BetHistoryVersion}`);

                    const round_unique_id_index = store.index("RoundUniqueID");
                    const round_unique_id_query = round_unique_id_index.getAll(getBrowserAgent() === "Safari" ? round_unique_id : [round_unique_id]);
                    round_unique_id_query.onsuccess = async (e) => {
                        let newTotal = 0;
                        let totalRemoved = 0;
                        for (const data of e.target.result) {
                            if (data.Status === status) {
                                await DeleteRow(store, data.Key);
                                const elements = document.querySelector(`[data-id-unique="${data.UniqueID}"]`);
                                elements?.remove();
                                totalRemoved += data.ChipAmount;
                                handleUpdateBetStatistic(connection, tableInfo, "DeductBetStatistic", data?.BetCode, { curValue: data?.ChipAmount });
                            } else {
                                newTotal += data.ChipAmount;
                            }
                        }
                        setTotalBet(newTotal);
                        setBalance((prev) => prev + totalRemoved);
                        return resolve();
                    };

                    transaction.oncomplete = () => {
                        db.close();
                    };
                };
            } else {
                if (status === 0) {
                    const elements = document.querySelectorAll(`[data-id="${round_unique_id}"]`);
                    elements.forEach((item) => item.parentNode.removeChild(item));
                }
            }

            if (document.querySelectorAll(".shine")?.length > 0) {
                document.querySelector(`#confirm-btn`)?.classList?.remove("shine");
            }
            return resolve({ totalBet: 0 });
        });
    }

    /**
     *
     * @param {string} round_unique_id gameSummarySrno
     *
     */
    function handleUpdateBetToSuccess(round_unique_id, setTotalBet) {
        return new Promise((resolve, reject) => {
            const request = window.indexedDB.open("Galaxy_Bet_History", BetHistoryVersion);
            request.onupgradeneeded = (e) => {
                CreateStore(e.target.result);
            };
            request.onsuccess = (e) => {
                const db = e.target.result;
                const transaction = db.transaction(`Log_Chips_Placed-${BetHistoryVersion}`, "readwrite");
                const store = transaction.objectStore(`Log_Chips_Placed-${BetHistoryVersion}`);

                const round_unique_id_index = store.index("RoundUniqueID");
                const round_unique_id_query = round_unique_id_index.getAll(getBrowserAgent() === "Safari" ? round_unique_id : [round_unique_id]);
                round_unique_id_query.onsuccess = async (e) => {
                    let total = 0;
                    for (const data of e.target.result) {
                        if (data.Status === 2) {
                            store.put({ ...data, Status: 3 });
                            total += data.ChipAmount;
                        }
                    }
                    setTotalBet(total);
                    return resolve({ totalBet: total });
                };

                if (document.querySelectorAll(".shine")?.length > 0) {
                    document.querySelector(`#confirm-btn`)?.classList?.remove("shine");
                }

                transaction.oncomplete = () => {
                    db.close();
                };
            };
        });
    }

    /**
     *
     * @param {array of object} chips_set array return by handleCreateChip
     * @param {object} dealer_signal dealerSignal
     * @returns
     */
    function handleDefaultPlaceChipFixed(chips_set, dealer_signal) {
        return new Promise((resolve, reject) => {
            const request = window.indexedDB.open("Galaxy_Bet_History", BetHistoryVersion);
            request.onupgradeneeded = (e) => {
                CreateStore(e.target.result);
            };
            request.onsuccess = (e) => {
                const db = e.target.result;
                const transaction = db.transaction(`Log_Chips_Placed-${BetHistoryVersion}`, "readwrite");
                const store = transaction.objectStore(`Log_Chips_Placed-${BetHistoryVersion}`);

                const round_unique_id_index = store.index("RoundUniqueID");
                const round_unique_id_query = round_unique_id_index.getAll(getBrowserAgent() === "Safari" ? dealer_signal?.gameSummarySrno : [dealer_signal?.gameSummarySrno]);

                round_unique_id_query.onsuccess = async (e) => {
                    let total_bet = 0;
                    for (const [index, data] of e.target.result?.entries()) {
                        const elem = document.querySelectorAll(`[data-id-unique="${data?.UniqueID}"]`);
                        if (elem.length <= 0) {
                            total_bet += data?.ChipAmount;

                            const amount = e.target.result?.reduce((accumulator, curr, i, arr) => {
                                return curr?.BetCode === data?.BetCode && i <= index ? accumulator + curr?.ChipAmount : accumulator;
                            }, 0);

                            handlePlaceChipFixed(chips_set, data?.BetCode, { image: data?.ChipImage, value: amount }, data?.UniqueID, data?.RoundUniqueID);
                        }
                    }
                    return resolve({ totalBet: total_bet });
                };

                transaction.oncomplete = () => {
                    db.close();
                };
            };
        });
    }

    /**
     *
     * @param {object} connection connection
     * @param {string} table_info direct get tableInfo from params without split
     * @param {string} action AddBetStatistic / DeductBetStatistic
     * @param {string} key bet code
     * @param {object} result {curValue: 12} !important
     */
    function handleUpdateBetStatistic(connection, table_info, action, key, result) {
        if (key.includes("-")) {
            const betCode = key.split("-");
            betCode.forEach((element) => {
                connection.invoke(action, {
                    tableInfo: table_info?.split("_")[1],
                    betCode: element,
                    amount: result?.curValue / 2,
                });
            });
        } else {
            connection.invoke(action, {
                tableInfo: table_info?.split("_")[1],
                betCode: key,
                amount: result?.curValue,
            });
        }
    }
    /**
     *
     * @param {object} connection connection
     * @param {object} dealer_signal
     * @param {string} table_info direct get tableInfo from params without split
     */
    async function handleConfirmBet(connection, dealer_signal, table_info) {
        const betItemsObj = await handleSortBetItems(dealer_signal, table_info);
        if (betItemsObj) {
            await connection
                .invoke("PlayerBet", {
                    playerID: localStorage.getItem("playerID"),
                    playerToken: localStorage.getItem("playerToken"),
                    gameSummarySrno: dealer_signal?.gameSummarySrno,
                    gameType: table_info?.split("_")[0],
                    tableInfo: table_info?.split("_")[1],
                    IPv4: await getIPAddress(),
                    // IPv4: "",
                    ...betItemsObj,
                })
                // .then(() => message.success({ content: "Bet Success", key: "bet-success" }))
                .catch((error) => {
                    Swal.fire({ text: error, icon: "error" });
                });
        }
    }

    return {
        handleDisplayRate,
        handlePlaceBet,
        handleUndoBet,
        handleReBet,
        handleDoubleBet,
        handleSortBetItems,
        handleRemoveAllChip,
        handleDefaultPlaceChipFixed,
        handleUpdateBetStatistic,
        handleConfirmBet,
        handleUpdateBetToSuccess,
    };
}
