import { useContext, useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Swal from "sweetalert2";
import moment from "moment";
import { AuthContext } from "../../../context/AuthContext";
import { handleStartStreaming, handleStopStreaming } from "../../../utils/LiveStreaming";
import { handleCheckExpired, handleGetPlayerInfo } from "../../../utils/SignalR";

import { useSignalR } from "../../../hook/useSignalR";
import { useChips } from "../../../hook/useChips";
import { useBetPanel } from "../../../hook/useBetPanel";
import { useFourSeasonBetPanel } from "./useFourSeasonBetPanel";
import { message } from "antd";

export function useFourSeason() {
    const { tableInfo } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [isLiveLoading, setIsLiveLoading] = useState(true);

    const { userData, setUserData, balance, setBalance } = useContext(AuthContext);

    const { connection, actionTime, setActionTime, StopConnect, chatMsg } = useSignalR(process.env.REACT_APP_FOUR_SEASONS_URL);
    const { chipsArr, handleCreateChip } = useChips();
    const { handleRemoveAllChip, handleUpdateBetToSuccess } = useBetPanel();

    const { handleConfirmBet } = useFourSeasonBetPanel();

    const [tableDetail, setTableDetail] = useState();
    const [betItems, setBetItems] = useState();
    const [roadMaps, setRoadMaps] = useState();
    const streamingRef = useRef();
    const [streamingObj, setStreamingObj] = useState();

    const [dealerSignal, setDealerSignal] = useState({});
    const [totalBet, setTotalBet] = useState(0);
    const [betStatistic, setBetStatistic] = useState({
        total: 0,
        b1: 0,
        b2: 0,
        b3: 0,
        p1: 0,
        p2: 0,
        p3: 0,
    });
    const [winList, setWinList] = useState();
    const [cardResult, setCardResult] = useState();
    const [labelBet, setLabelBet] = useState("totalBet");
    const [actionMsg, setActionMsg] = useState("nextRound");
    const [reviewCard, setReviewCard] = useState(false);
    const [jackpotDescription, setJackpotDescription] = useState(false);
    const [jackpotDescription1, setJackpotDescription1] = useState(false);
    const [jackpotDescription2, setJackpotDescription2] = useState(false);
    const [jackpotDescription3, setJackpotDescription3] = useState(false);

    const [receivedStopBet, setReceivedStopBet] = useState();
    const [gameType, setGameType] = useState();
    const [betContainerClass, setBetContainerClass] = useState(false);
    useEffect(() => {
        handleFirstLoad();
        return () => handleComponentUnmount();
    }, [connection]);

    function handleFirstLoad() {
        if (connection) {
            connection.invoke("GetTableDetail", {
                playerID: localStorage.getItem("playerID"),
                playerToken: localStorage.getItem("playerToken"),
                gameType: tableInfo?.split("_")[0],
                tableInfo: tableInfo?.split("_")[1],
            });

            handleListenSignalR();
            setActionTime(moment());
            setIsFirstLoad(false);
        }
    }

    function handleComponentUnmount() {
        StopConnect();
        handleStopStreaming(streamingRef, setIsLiveLoading);
    }

    function handleListenSignalR() {
        if (connection) {
            connection.on("table-detail", (result) => {
                if (result.status) {
                    handleCreateChip(result?.data?.chips);
                    handleStartStreaming(streamingRef, result?.data?.liveURL, 1080, setIsLiveLoading);

                    setTableDetail(result?.data);
                    setBetItems(result?.data2);
                    setStreamingObj({
                        ref: streamingRef,
                        url: result?.data?.liveURL,
                        graphic: 1080,
                        setState: setIsLiveLoading,
                    });
                }
            });

            connection.on("game-route", (result) => {
                setRoadMaps((prev) => (result.status ? result : prev));
            });

            connection.on("received-signal", (result) => {
                if (result.tableCode === tableDetail?.tableCode) {
                    connection.off("bet-response");

                    setActionMsg();
                    setTimeout(() => {
                        setDealerSignal(result);
                    }, 300);
                }
            });

            connection.on("bet-statistic", (result) => {
                setBetStatistic(result);
            });

            connection.on("player-card", (result) => {
                result.status ? setCardResult(result.data) : setCardResult();
            });

            connection.on("bet-code-result", (result) => {
                if (result.status) {
                    setActionMsg();
                    setWinList(result.data2);
                    result?.data?.forEach((item) => {
                        document.querySelector(`[data-id="${item}"]`)?.classList?.add("win");
                    });

                    if (result?.data?.includes("T")) {
                        handleGetPlayerInfo(connection);
                    } else {
                        setTotalBet(0);
                        result?.data2?.find((item) => {
                            if (item.playerID === userData?.playerID) {
                                setTotalBet(item.winAmount);
                            }
                        });
                    }
                }
            });
        }
    }

    useEffect(() => {
        handleDealerSignal(dealerSignal);
    }, [dealerSignal]);

    /**
     *
     * @param {object} signal dealer signal
     */
    async function handleDealerSignal(signal) {
        connection?.on("bet-response", async (result) => {
            setUserData(result.data2);
            // setBalance(Math.trunc(result.data2.balance * 100) / 100);

            if (!result.status) {
                Swal.fire({ text: t(result.message) });
                await handleRemoveAllChip(signal?.gameSummarySrno, true, 2, setTotalBet, connection, tableInfo);
            } else {
                // setActionTime(moment);
                await handleUpdateBetToSuccess(signal?.gameSummarySrno, setTotalBet);
                message.success({ content: "Bet Success", key: "bet-success" });
            }
        });

        // if (signal?.gameSummarySrno) {
        //   await handleDefaultPlaceChipFixed(chipsArr, signal).then((result) => {
        //     setTimeout(() => {
        //       setTotalBet((prev) => prev + result.totalBet);
        //     }, 300);
        //   });
        // }

        switch (signal?.action) {
            case "new-game":
                setActionMsg("startBet");

                setReceivedStopBet(false);
                setTotalBet(0);
                setBetStatistic({ total: 0, b1: 0, b2: 0, b3: 0, p1: 0, p2: 0, p3: 0 });
                handleCheckExpired(actionTime, setActionTime, tableInfo, t, navigate);
                handleRemoveClassName("win");
                setBetContainerClass(true);
                await handleRemoveAllChip(signal?.gameSummarySrno, false, 0, setTotalBet, connection, tableInfo);

                break;
            case "stop-bet":
                setReceivedStopBet(true);
                setActionMsg("stopBet");
                // handleConfirmBet(connection, signal, tableInfo);

                handleRemoveClassName("win");
                setBetContainerClass(false);
                // setReviewCard(true);
                await handleRemoveAllChip(signal?.gameSummarySrno, true, 1, setTotalBet, connection, tableInfo);

                break;
            case "peek-card":
                setReviewCard(true);
                break;
            case "light-code":
                if (!receivedStopBet) {
                    await handleRemoveAllChip(signal?.gameSummarySrno, true, 1, setTotalBet, connection, tableInfo);
                }
                setLabelBet("lastWin");
                break;
            case "next-game":
                setActionMsg("nextRound");
                await handleRemoveAllChip(signal?.gameSummarySrno, false, 0, setTotalBet, connection, tableInfo);
                if (totalBet !== 0) {
                    handleGetPlayerInfo(connection);
                }

                setReceivedStopBet(false);
                setTotalBet(0);
                setCardResult();
                setWinList();
                setBetStatistic({ total: 0, b1: 0, b2: 0, b3: 0, p1: 0, p2: 0, p3: 0 });
                handleRemoveClassName("win");
                break;
            case "last-game":
                await handleRemoveAllChip(signal?.gameSummarySrno, false, 0, setTotalBet, connection, tableInfo);

                setReceivedStopBet(false);
                setTotalBet(0);
                setCardResult();
                setWinList();
                setBetStatistic({ total: 0, b1: 0, b2: 0, b3: 0, p1: 0, p2: 0, p3: 0 });
                handleRemoveClassName("win");
                break;
            case "cancel-round":
                setActionMsg("cancelRound");
                await handleRemoveAllChip(signal?.gameSummarySrno, false, 0, setTotalBet, connection, tableInfo);

                setReceivedStopBet(false);
                setTotalBet(0);
                setCardResult();
                setWinList();
                setBetStatistic({ total: 0, b1: 0, b2: 0, b3: 0, p1: 0, p2: 0, p3: 0 });
                handleRemoveClassName("win");
                break;
            default:
                handleRemoveClassName("win");
                break;
        }
    }

    function handleRemoveClassName(classname) {
        const elems = document.querySelectorAll(`.${classname}`);
        elems?.forEach((element) => {
            element.classList.remove(classname);
        });
    }

    return {
        tableInfo,
        isFirstLoad,
        isLiveLoading,
        userData,
        balance,
        connection,
        chipsArr,
        tableDetail,
        betItems,
        roadMaps,
        streamingObj,
        dealerSignal,
        totalBet,
        setTotalBet,
        betStatistic,
        winList,
        cardResult,
        labelBet,
        setLabelBet,
        actionMsg,
        setActionTime,
        betContainerClass,
        setBetContainerClass,
        setReviewCard,
        reviewCard,
        jackpotDescription,
        setJackpotDescription,
        gameType,
        setGameType,
        chatMsg,
    };
}
