import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Spin } from "antd";
import { formatNumber } from "../../../utils/Formatter";
import { useFourSeason } from "../hook/useFourSeason";

import "./HFourSeason.scss";

import LoadingPage from "../../../pages/loading-page/LoadingPage";
import Icon from "../../../components-game/navigator/icon/Icon";
import TableList from "../../../components-game/table-list/TableList";
import { SyncOutlined } from "@ant-design/icons";
import CFBtnView from "../../../components-game/navigator/CFBtnView";
import ActionMessage from "../../../components-game/action-message/ActionMessage";
import Timer from "../../../components-game/timer/Timer";
import VChips from "../../../components-game/chips-set/vertical/VChips";
import WinList from "../../../components-game/win-list/WinList";
import BetPanel from "../component/bet-panel/BetPanel";
import table from "../../../assets/game/common/table.png";
import TryFullscreenModal from "../../../components/common/try-fullscreen-modal/TryFullscreenModal";
import ReviewCard from "../component/reveal-card/ReviewCard";
import Dot from "../component/road-map/dots/Dot";
import RoadMap from "../component/road-map/RoadMap";
import BankerCard from "../component/banker-card/BankerCard";

import { InfoCircleOutlined } from "@ant-design/icons";
import { useBetPanel } from "../../../hook/useBetPanel";
import back from "../../../assets/game/common/back.png";
import { useNavigate } from "react-router-dom";
import HChips from "../../../components-game/chips-set/new-horizontal/HChips";

function HFourSeason() {
    const navigate = useNavigate();
    const {
        tableInfo,
        isFirstLoad,
        isLiveLoading,
        userData,
        balance,
        connection,
        chipsArr,
        tableDetail,
        betItems,
        roadMaps,
        streamingObj,
        dealerSignal,
        totalBet,
        setTotalBet,
        betStatistic,
        winList,
        cardResult,
        setCardResult,
        labelBet,
        setLabelBet,
        actionMsg,
        betType,
        setBetType,
        reviewCard,
        setReviewCard,
        betContainerClass,
        setBetContainerClass,
        jackpotDescription,
        setJackpotDescription,
        gameType,
        setGameType,
        chatMsg,
    } = useFourSeason();

    const { handleDisplayRate } = useBetPanel();

    const [openTableList, setOpenTableList] = useState(false);
    const { t } = useTranslation();
    if (isFirstLoad) {
        return <LoadingPage />;
    }
    function handleChangeBetPanelSkew() {
        if ((dealerSignal?.action === "new-game" && !betContainerClass) || dealerSignal?.action !== "new-game") {
            setBetContainerClass((prev) => !prev);
        }

        // console.log(betContainerClass);
    }

    function handleChangeContainerBetPanelSkew(e) {
        if (dealerSignal?.action === "new-game" && e.target.className === "horizontal-four-seasons-container") {
            setBetContainerClass((prev) => (!betContainerClass ? prev : !prev));
            // console.log(e.target.className, betContainerClass);
        }
        // console.log(dealerSignal?.action);
    }

    function handleChangeComm() {
        return;
    }

    return (
        <div className="horizontal-four-seasons">
            <Spin spinning={isLiveLoading}>
                <div className="live-streaming-container">
                    <canvas id="video" style={{ width: "100%", height: "100%" }} />
                </div>
            </Spin>

            <div className="horizontal-four-seasons-container" onClick={(e) => handleChangeContainerBetPanelSkew(e)}>
                <div className="header-container">
                    <div className="header-title">
                        <div className="header-btn" onClick={() => navigate(-1)}>
                            <Icon image={back} />
                        </div>
                        {tableDetail?.tableName}
                    </div>
                    <div className="header-menu">
                        {streamingObj && (
                            <CFBtnView
                                BtnTable={true}
                                BtnGraphic={true}
                                connection={connection}
                                GraphicObj={streamingObj}
                                tableDetail={tableDetail}
                                chatMsg={chatMsg}
                            />
                        )}
                    </div>
                </div>

                <div className={`action-msg-container ${actionMsg}`}>
                    {actionMsg && <ActionMessage text={actionMsg} color="gold" totalWin={totalBet} />}
                </div>

                {dealerSignal?.second ? (
                    <div className="timer-container">
                        <Timer dealerSignal={dealerSignal} />
                    </div>
                ) : (
                    ""
                )}

                <div className="win-list-wrapper" style={{ display: winList?.length > 0 ? "block" : "none" }}>
                    {winList?.length > 0 && <WinList winList={winList} />}
                </div>

                <div className={`bet-wrapper ${betContainerClass ? "new-game" : "stop-bet"}`} onClick={() => handleChangeBetPanelSkew()}>
                    <div className="jackpot-info-icon" onClick={() => setJackpotDescription(!jackpotDescription)}>
                        <InfoCircleOutlined />
                    </div>
                    {betItems && (
                        <div className={`jackpot-info ${jackpotDescription}`}>
                            <div>
                                <div>{t("royalStraightFlush")} :</div>
                                <div>{t("straightFlush")} :</div>
                                <div> {t("fourOfAKind")} :</div>
                                <div>{t("flush")} :</div>
                                <div>{t("straight")} :</div>
                                <div>{t("threeOfAKind")} :</div>
                                <div>{t("twoPair")} :</div>
                            </div>
                            <div className="bet-rate">
                                <div>{handleDisplayRate(betItems, "BS3JRF")}</div>
                                <div>{handleDisplayRate(betItems, "BS3JSF")}</div>
                                <div>{handleDisplayRate(betItems, "BS3J4K")}</div>
                                <div>{handleDisplayRate(betItems, "BS3JF")}</div>
                                <div>{handleDisplayRate(betItems, "BS3JS")}</div>
                                <div>{handleDisplayRate(betItems, "BS3J3K")}</div>
                                <div>{handleDisplayRate(betItems, "BS3J2P")}</div>
                            </div>
                        </div>
                    )}

                    <div className="bet-panel-container">
                        <div className="bet-panel">
                            {betItems && (
                                <BetPanel
                                    tableInfo={tableInfo}
                                    connection={connection}
                                    chipArr={chipsArr}
                                    rate={betItems}
                                    tableDetail={tableDetail}
                                    dealerSignal={dealerSignal}
                                    setTotalBet={setTotalBet}
                                    betStatistic={betStatistic}
                                    setLabelBet={setLabelBet}
                                    cardResult={cardResult ? cardResult : []}
                                />
                            )}
                        </div>

                        <div className="banker-card-wrapper">
                            <div className="title" style={{ display: cardResult ? "block" : "none" }}>
                                {t("bankerCard")}
                            </div>
                            {cardResult && <BankerCard tableDetail={tableDetail} dealerSignal={dealerSignal} cardResult={cardResult[0]} />}
                        </div>
                    </div>

                    <div className="bet-panel-btn-wrapper">
                        <div className="chips-set-wrapper">
                            {chipsArr && (
                                <HChips
                                    setLabelBet={setLabelBet}
                                    connection={connection}
                                    tableInfo={tableInfo}
                                    ChipsSet={chipsArr}
                                    tableDetail={tableDetail}
                                    dealerSignal={dealerSignal}
                                    totalBet={totalBet}
                                    setTotalBet={setTotalBet}
                                    gameType={gameType}
                                    setGameType={setGameType}
                                    showComm={false}
                                    roadMaps={roadMaps}
                                />
                            )}
                        </div>
                    </div>

                    <div className="road-maps-statistic-container">
                        <div className="statistic-count">
                            <div className="lbl lbl-title">{`${t("last")} ${roadMaps?.data2?.total} ${t("result")}`}</div>

                            <div className="lbl">
                                <div className="lbl-dot">
                                    <Dot LastColor="#3788ff" text2="P1" Jackpot bankerPlayer LineBottom={false} LineRight={false} />
                                </div>
                                <div className="content">{roadMaps?.data2?.player_1}</div>
                            </div>

                            <div className="lbl">
                                <div className="lbl-dot">
                                    <Dot LastColor="#3788ff" text2="P2" Jackpot bankerPlayer LineBottom={false} LineRight={false} />
                                </div>
                                <div className="content">{roadMaps?.data2?.player_2}</div>
                            </div>

                            <div className="lbl">
                                <div className="lbl-dot">
                                    <Dot LastColor="#3788ff" text2="P3" bankerPlayer Jackpot LineBottom={false} LineRight={false} />
                                </div>
                                <div className="content">{roadMaps?.data2?.player_3}</div>
                            </div>
                        </div>
                    </div>

                    <div className={`road-maps-container`}>
                        <div className="road-1">
                            <RoadMap Data={roadMaps?.data?.route_1} />
                        </div>
                    </div>
                </div>

                <div className="footer-container total-bet">
                    <div className="footer-wrapper">
                        <span className="lbl lbl-total-bet">{t(labelBet) + ": " + userData?.currency + " " + formatNumber(totalBet)}</span>
                        <span className="lbl lbl-balance">{t("balance") + ": " + userData?.currency + " " + formatNumber(balance)}</span>
                    </div>
                </div>

                <div className="footer-container max-bet">
                    <div className="footer-wrapper">
                        <span className="lbl lbl-max-bet">{`${userData?.currency + " " + tableDetail?.minBet + " - " + tableDetail?.maxBet}`}</span>
                        <div className="id-wrapper">
                            <span className="lbl lbl-id">ID: {userData?.playerID ? userData.playerID : "-"}</span>
                            <span className="lbl lbl-id">UID: {dealerSignal?.gameSummarySrno ? dealerSignal.gameSummarySrno : "-"}</span>
                        </div>
                    </div>
                </div>

                {/* <div className="tbl-btn-container">
                    <div className="tbl-btn-wrapper" onClick={() => setOpenTableList(!openTableList)}>
                        <Icon image={table} />
                    </div>

                    <TableList connection={connection} tableInfo={tableInfo} openTableList={openTableList} setOpenTableList={setOpenTableList} />
                </div> */}
            </div>

            {dealerSignal?.action === "peek-card" && cardResult && reviewCard && (
                <ReviewCard tableDetail={tableDetail} result={cardResult} setReviewCard={setReviewCard} />
            )}

            {/iPhone|iPad/.test(window.navigator.userAgent) && <TryFullscreenModal />}
        </div>
    );
}

export default HFourSeason;
